import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

export const RoleGuard = (next: ActivatedRouteSnapshot): CanActivateFn => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const allowedRoles = next.data ? next.data.roles : ([] as Array<string>);
  const canActivate = allowedRoles.length && authService.hasRole(allowedRoles) ? true : false;

  if (canActivate === false) router.navigate(["/page-not-found"]);
  return () => {
    return canActivate;
  };
};
